<template>
    <div>
        <!-- main page -->
        <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/1 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>                
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto"></div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : SO Code or Customer Name" />
                        </div>
                    </div>
                </div>

                <vs-table :data="dataSalesOrder" stripe border>
                    <template slot="thead">
                        <vs-th>Sales Order</vs-th>
                        <vs-th>Company / Customer</vs-th>
                        <vs-th>Order</vs-th>
                        <vs-th>Valuation</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <!-- Turquoise -->
                        <!-- Violet -->
                        <!-- LightSeaGreen -->
                        <!-- Tomato -->
                        <!-- Brown -->
                        <!-- SeaGreen -->
                        <!-- approval_status: 0,
                        approval_manager: 0,
                        approval_release: 0,
                        validation_status: 0,
                        allocation_status: 0,
                        0: (default) belom di allocation
                        1: complete => terpenuhi semua qty & item
                        2: partial - redeliver => create SO baru sesuai Qty yg tidak terpenuhi
                        3: partial - confirmation => Qty req tidak terpenuhi semua =>  -->
                        <vs-tr :key="indexSO" v-for="(dt, indexSO) in data">
                            <vs-td>
                                Code : {{dt.Code}}<br>
                                Promo : {{dt.SalesOrderPromo && dt.SalesOrderPromo[0] ? dt.SalesOrderPromo[0].PromoCode : '-' }}<br>
                                Source : <b>{{dt.Source}}</b> <br>
                                Sales : <b>{{dt.Salesman.Name}}</b> <br>
                                Channel : ({{dt.SalesChannel.Code}}) {{dt.SalesChannel.Name}} <br>
                                Delivery Date : {{dateFormat(dt.DueDate)}} <br>
                                Total Line : {{dt.SalesOrderLine.length}} <br>
                                Notes : {{dt.Notes}}
                            </vs-td>
                            <vs-td>
                                <i><b>Company</b></i> <br>
                                Code : {{dt.Company.Code}} <br>
                                Name : {{dt.Company.Name}} <br>
                                <br><hr><br>
                                <i><b>Customer</b></i> <br>
                                Category : {{dt.Customer.CustomerCategory.Name}} <br>
                                Code : {{dt.Customer.Code}} <br>
                                Name : {{dt.Customer.Name}} <br>
                                Bill to : {{dt.BillAddress.Address}}, {{dt.BillAddress.City}}, {{dt.BillAddress.Country}}<br>
                                Ship to : {{dt.ShipAddress.Address}}, {{dt.ShipAddress.City}}, {{dt.ShipAddress.Country}}<br>
                                Payment term : {{dt.PaymentTerm.Name}}
                            </vs-td>
                            <vs-td>
                                <table>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td align="right">{{ formatCurrency(dt.Subtotal) }}</td>
                                    </tr>
                                    <tr>
                                        <td>Discount</td>
                                        <td align="right">{{ formatCurrency(dt.Discount) }}</td>
                                    </tr>
                                    <tr>
                                        <td>Nett</td>
                                        <td align="right">{{ formatCurrency(dt.Subtotal - dt.Discount) }}</td>
                                    </tr>
                                    <tr>
                                        <td>Tax</td>
                                        <td align="right">{{ formatCurrency(dt.TaxAmount) }}</td>
                                    </tr>
                                    <tr>
                                        <td>Charge</td>
                                        <td align="right">{{ formatCurrency(dt.SubtotalCharge) }}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td align="right">{{ formatCurrency(dt.Total) }}</td>
                                    </tr>
                                </table>
                            </vs-td>
                            <vs-td>
                                <table>
                                    <tr>
                                        <td>COGS</td>
                                        <td align="right">{{ formatCurrency(dt.TotalCogs) }}</td>
                                    </tr>
                                    <tr>
                                        <td>Margin</td>
                                        <td align="right">{{ formatCurrency(dt.Total - dt.TotalCogs) }}</td>
                                    </tr>
                                    <tr>
                                        <td>Margin (sales) %</td>
                                        <td align="right">{{ calculateMarginSales((dt.Subtotal - dt.Discount), dt.TotalCogs) }}%</td>
                                    </tr>
                                </table>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
        
                <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
            </div>
        </div>
        <vs-popup fullscreen title="Sales Order Lines" :active.sync="detail">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <detailSelectedSo :selectedSO="selectedSO"></detailSelectedSo>
                </div>
            </div>
        </vs-popup>
    </div>
</template>


<script>
import detailSelectedSo from "../component/detail_selected_so.vue";
import moment from 'moment'

export default {
    components: {
        detailSelectedSo
    },
    props: 
        ["progress", "progressWarehouse", "progressRestriction", "isCancel","territory", "salesChannel", "customerCategory", "isClosed", "isBilled", "salesPersonal"],
        mounted() {
            this.selectedSO = this.dataSalesOrder
        },
        data() {
            return {
                selectedSO: {},
                dataSalesOrder: [{
                    SalesOrderLine: [{}],
                    Customer: {
                        Code: "",
                        Name: "",
                        Address: "",
                        Phone: "",
                        CustomerCategory: {
                            Name: ""
                        }
                    },
                    Company: {
                        Code: "",
                        Name: "",
                    },
                    SalesChannel: {
                        Code: "",
                        Name: ""
                    },
                    BillAddress: {
                        Address: "",
                        City: "",
                        Country: "",
                    },
                    ShipAddress: {
                        Address: "",
                        City: "",
                        Country: "",
                    },
                    Salesman: {
                        Name: "",
                    },
                    PaymentTerm: {
                        Name: "",
                    }
                }],
                salesOrderCode: "",

                limits: [10, 25, 50, 100, "All"],
                limitShow: 10,
                drawData: 0,
                isActive: 1,
                search: "",
                detailShow: "core vx-col md:w-1/2 w-full mb-base ",
                detailHide: "core vx-col md:w-1/1 w-full mb-base ",
                detail: false,
                recordsTotal: 0,
                maxPage: 2,
                dataEnd: 0,
            };
        },
    watch: {
        watchedProperties: function() {
            this.getData();
        }
    },
    computed: {
        watchedProperties() {
            return `${this.territory}||${this.salesChannel}||${this.customerCategory}||${this.search}||${this.salesPersonal.id}`;
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                this.page(page);
            }
        }
    },
    methods: {
        dateFormat(date) {
            if (date) {
                return moment(String(date)).utcOffset(0).format('DD/MM/YYYY hh:mm')
            }
        },

        formatCurrency(nominal) {
            if(nominal) { // nominal > 0 untuk handle return NaN
                if(nominal > 0) {
                    nominal = (nominal/1).toFixed(2).replace('.', ',');
                    return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    nominal = nominal * -1
                    nominal = (nominal/1).toFixed(2).replace('.', ',');
                    return "-"+nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            } else {
                return 0;
            }
        },

        calculateMarginCost(total, totalCogs) {
            let margin;
            if(total > totalCogs) {
                margin = (total - totalCogs) / totalCogs;
            } else {
                margin = ((totalCogs - total) * -1) / totalCogs;
            }
            return margin * 100;
        },

        calculateMarginSales(total, totalCogs) {
            let margin;
            if(total > totalCogs) {
                margin = (total - totalCogs) / total;
            } else {
                margin = ((totalCogs - total) * -1) / total;
            }
            return margin * 100;
        },

        scrollToTop() {
            window.scrollTo(0,0);
        },

        getSOLine () {
            const search = this.salesOrderCode;
            if (search) {
                this.selectedSO = this.dataSalesOrder.filter(so => so.Code.indexOf(search) > -1)[0] // filter + get 1st record
            }
        },

        clickDelete(salesOrder) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "You are about to cancel '"+salesOrder.Code+"' ?",
                accept: this.actionDelete,
                parameters: salesOrder
            });
        },

        actionDelete(salesOrder) {
            this.$vs.loading();
            this.$http
                .post("api/v1/sales-order/cancel", {
                    sales_order_code: salesOrder.Code,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Sales Order successfully cancelled",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    }
            });
        },

        clickSOCode(salesOrder) {
            this.closeDetail();
            setTimeout(function() {}, 3000);
            this.salesOrderCode = salesOrder.Code;
            this.getSOLine();
            this.detail = true;
            this.scrollToTop();
        },

        closeDetail() {
            this.detail = false;
            this.$emit("selectSalesOrder", null);
        },

        page(page) {
            this.getData(page);
            return page;
        },

        changeLimit(limit) {
            this.limitShow = limit;
            this.getData(this.isActive);
        },

        getData(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            var parameter = {
                territory_code: this.territory,
                sales_channel_code: this.salesChannel,
                customer_category_id: this.customerCategory,
                progress_status: this.progress,
                progress_restriction: this.progressRestriction,
                is_cancel: this.isCancel,
                is_closed: this.isClosed,
                is_billed: this.isBilled,
                sales_personal_id: this.salesPersonal.id,
                search: this.search,
                limit: this.limitShow,
                offset: this.limitShow * (page - 1)
            };

            if(this.progressRestriction == false) {
                parameter.approval_status = 0;
                parameter.approval_manager = 0;
                parameter.approval_release = 0;
                parameter.validation_status = 0;
                parameter.allocation_status = 0;
            }

            this.$http.get("/api/v1/sales-order", {
                params: parameter
            }).then(resp => {
                if (resp.code == 200) {
                    this.drawData = this.drawData + 1;
                    this.dataSalesOrder = resp.data.SalesOrders;
                    this.recordsTotal = resp.data.RecordsTotal;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.ResultsTotal;
                    this.checkedAll = false;
                    this.$vs.loading.close();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            });
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>